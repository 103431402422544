import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import map from "lodash/map"
import Img from "gatsby-image"
import Post from "../views/layouts/Post"

const ProductsCategoryPage = props => {
  const products = get(props, "data.categoryJson.edges", []);
  const allCategories = get(props, "pageContext.allCategories", []);
  return (
    <Post location="产品列表" allCategories={allCategories}>
      {map(products, (item, index) => (
        <div className="single-top" key={index}>
          <div className="left-blog">
            <Link to={`/products/${item.node.slug}`}>
              <Img
                className="img-responsive"
                fluid={get(item, "node.thumbnail.fluid", "")}
              />
            </Link>
          </div>
          <div className="top-blog">
            <Link to={`/products/${item.node.slug}`} className="fast">
              {get(item, "node.productName.productName")}
            </Link>
            <div className="time">
              <i className="icon-time"></i> {item.node.createdAt}
            </div>
            <Link
              to={`/products/${item.node.slug}`}
              className="btn btn-primary"
            >
              了解更多
            </Link>
          </div>
          <div className="clearfix"> </div>
        </div>
      ))}
    </Post>
  )
}

export default ProductsCategoryPage

export const pageQuery = graphql`
  query prouctPostsListByCategory($category: String) {
    categoryJson: allContentfulProduct(
      filter: { categories: { elemMatch: { slug: { in: [$category] } } } }
    ) {
      edges {
        node {
          slug
          thumbnail {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          createdAt(formatString: "MMMM Do, YYYY")
          productName {
            productName
          }
          description {
            description
          }
          categories {
            title {
              title
            }
            slug
          }
        }
      }
    }
  }
`
